<template>
  <div>
    <md-scroll-view ref="scrollView"
                    auto-reflow
                    :scrolling-x="false"
                    class="scroll"
                    @end-reached="loadMore">
      <div class="item"
           v-for="(item,index) in lists"
           :key="index">
        <div class="itemname"
             @click="tokhwj(item.userid)">
          <!-- <div class="index">
            <span>{{ index + 1 }}.</span>
          </div> -->
          <div class="headimg">
            <img :src="item.headimg"
                 alt="">
          </div>
          <div class="name">
            <span class="name1">{{ item.name }}</span>
            <br />
            <span class="name2">姓名:{{ item.username }}</span>
          </div>
        </div>
        <div class="time">
          <span class="intime">{{ item.intime.substr(5,13) }}</span>
          <br />
          <a :href="'tel:' + item.mobile">
            <span>
              <md-icon name="phone"
                       color="#228BCD"
                       class="iconPhone"
                       size="md"></md-icon> {{ item.mobile }}
            </span>
          </a>
        </div>
      </div>
      <md-scroll-view-more slot="more"
                           :is-finished="loading" />
    </md-scroll-view>
  </div>
</template>
<script>
import { getexchangeMobileList } from '@/api/abt/customerOperation/visitingcard'
import loadMore from '@/mixins/loadmore'
export default {
  mixins: [loadMore],
  data () {
    return {
      pageNo: 1,
      size: 10,
      total: 0,
      totalPage: 0,
      loading: false,
      lists: [
        {
          intime: ''
        }
      ],
      height: ''
    }
  },
  created () {
    this.getData()
    this.height = document.body.clientHeight
  },
  mounted () {
    document.querySelector('.scroll').style.height = `${this.height}px`
  },
  methods: {
    async getData (isInit = true) {
      let { pageNo, size } = this
      if (!isInit) {
        this.pageNo = ++pageNo
        this.$refs.scrollView.finishLoadMore()
      }
      let data = {
        page: this.pageNo,
        size
      }
      let res = await getexchangeMobileList(data)
      this.lists = isInit ? res.data.data.rows : this.lists.concat(res.data.data.rows)
      this.total = res.data.data.total
      this.totalPage = res.data.data.totalpage
      return res
    },
    tokhwj (userid) {
      this.$router.push({
        name: 'clientAnalysis',
        query: { customer: userid.toString() }
      })
    }
  }
}
</script>
<style scoped lang="stylus">
@import './index.styl';
</style>
